import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  useTheme,
} from '@mui/material';

import type { RichTextContent } from '#components/RichText';
import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import RichText from '#components/RichText';
import columnCountOptions from '#utils/columnCountOptions';
import getMinHeight from '#utils/getMinHeight';
import { transformText } from '#utils/transformText';
import AccordionSummary from './AccordionSummary';
import type { AccordionOptionsDefinition } from '../AccordionOption/AccordionOptions';

export type AccordionItemDefinition = ContentfulComponentDefinition & {
  accordionTitle: RichTextContent,
  titleHeight: 'Small' | 'Medium' | 'Large',
  titleBackgroundColor: {
    value: string
  },
  titleHeader: String,
  prefix: String,
  suffix: String,
  content: RichTextContent,
  contentBackgroundColor: {
    value: string
  },
  listColumnCount: 1 | 2,
  id: string,
  internal: {
    type: 'ContentfulSubComponentAccordionItem'
  },
};

export type AccordionItemProps = Omit<AccordionItemDefinition, 'internal'> & {
  expanded: string | false,
  handleChange: (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => void,
  accordionOptions?: AccordionOptionsDefinition
};

const AccordionItem = ({
  id,
  accordionTitle,
  titleHeight,
  titleHeader,
  prefix,
  suffix,
  content,
  listColumnCount,
  expanded,
  handleChange,
  accordionOptions,
}: AccordionItemProps) => {
  const { color } = useTheme().palette;
  const customOptions = columnCountOptions(listColumnCount);
  const open = expanded === id;
  const textTitleColor = (open
    ? accordionOptions?.titleTextColorActive?.value
    : accordionOptions?.titleTextColorNonActive?.value) ?? 'black';

  const prefixColor = (open
    ? accordionOptions?.prefixSuffixActiveColor?.value
    : accordionOptions?.prefixSuffixNonActiveColor?.value) ?? 'black';

  const minHeight = getMinHeight(titleHeight);

  const textTransform = transformText(accordionOptions?.textTransform);

  return (
    <MuiAccordion
      expanded={open}
      onChange={handleChange(id)}
    >
      <AccordionSummary
        sx={{
          px: 2.7,
          py: 1,
          background: open
            ? accordionOptions?.titleBackgroundColorActive?.value
            : accordionOptions?.titleBackgroundColorNonActive?.value,
          minHeight,
          '&.Mui-expanded': {
            minHeight,
          },
        }}
        aria-controls={`${accordionTitle} - content`}
      >

        {
          prefix && (
          <p
            className={`font-bold text-2xl ${textTransform} pr-1 mt-0 mb-0`}
            style={{
              color: prefixColor,
            }}
          >
            {prefix}
            {' '}
            &nbsp;
          </p>
          )
        }
        <p
          className={`font-bold text-2xl ${textTransform} mt-0 mb-0`}
          style={{
            color: textTitleColor,
          }}
        >
          {titleHeader}
        </p>

        {
        suffix && (
          <p
            className={`font-bold text-2xl ${textTransform} pl-1 mt-0 mb-0`}
            style={{
              color: prefixColor,
            }}
          >
            {suffix}
          </p>
        )
        }

      </AccordionSummary>
      <MuiAccordionDetails sx={{
        p: 2.7,
        background: accordionOptions?.contentBackgroundColor?.value,
      }}
      >
        <Typography color={color?.impgray900} component="div">
          <RichText
            content={content}
            options={customOptions}
          />
        </Typography>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default AccordionItem;
