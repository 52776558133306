import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import Image from '#contentful/Image';
import getAlignment from '#utils/getAlignment';
import type { TabItem } from '../TabPanel';

type TabLabelProps = TabItem & {
  onChange: (event: React.SyntheticEvent, newSelectedTab: number) => void;
  index: number;
  active: boolean;
  fontSize?: string;
};

const TabLabel: React.FC<TabLabelProps> = ({
  title,
  options,
  onChange,
  index,
  active,
  fontSize,
}) => {
  const {
    textTransform,
    textAlign,
    tabItemDefaultIcon,
    tabItemActiveIcon,
    tabItemHoverIcon,
    iconPosition,
    tabItemDefaultBackground,
    tabItemDefaultFontColor,
    tabItemHoverBackground,
    tabItemHoverFontColor,
    tabItemActiveBackground,
    tabItemActiveFontColor,
    tabItemHoverBorder,
    tabItemActiveBorder,
    tabItemDefaultBorder,
  } = options ?? {};

  const [isHovered, setIsHovered] = useState(false);

  const baseStyles = {
    background: tabItemDefaultBackground?.value || 'transparent',
    color: tabItemDefaultFontColor?.value || '#000',

    ...((tabItemDefaultBorder && !active) && {
      borderTop: !tabItemDefaultBorder?.removeTopBorder ? `${tabItemDefaultBorder.width}px solid ${tabItemDefaultBorder.color?.value}` : 0,
      borderBottom: !tabItemDefaultBorder?.removeBottomBorder ? `${tabItemDefaultBorder.width}px solid ${tabItemDefaultBorder.color?.value}` : 0,
      borderRadius: '0px !important',
    }),

    opacity: 1,
    alignItems: getAlignment(textAlign),
    fontWeight: 700,
    fontSize: {
      xs: '0.75rem',
      md: fontSize || '0.85rem',
    },
    minHeight: '60px',
    maxWidth: {
      xs: '250px',
      md: '270px',
    },
    flexGrow: 1,
    wordWrap: 'break-word',
    wordBreak: {
      xs: 'break-word',
      md: 'normal',
    },
    textWrap: {
      xs: 'wrap',
      md: 'none',
    },
    textAlign: textAlign ?? 'left',
    textTransform: textTransform?.toLowerCase() ?? 'capitalize',
    '&:hover': {
      background: tabItemHoverBackground?.value || tabItemDefaultBackground?.value,
      color: tabItemHoverFontColor?.value || tabItemDefaultFontColor?.value,
      border: tabItemHoverBorder ? `${tabItemHoverBorder.width}px solid ${tabItemHoverBorder.color?.value}` : 0,
    },
    ...(active && {
      background: tabItemActiveBackground?.value || tabItemDefaultBackground?.value,
      color: tabItemActiveFontColor?.value || tabItemDefaultFontColor?.value,
      border: tabItemActiveBorder ? `${tabItemActiveBorder.width}px solid ${tabItemActiveBorder.color?.value}` : 0,
    }),
  };

  let iconElement;

  if (active && tabItemActiveIcon) {
    iconElement = <Box width="70px"><Image content={tabItemActiveIcon} /></Box>;
  } else if (isHovered && tabItemHoverIcon) {
    iconElement = <Box width="70px"><Image content={tabItemHoverIcon} /></Box>;
  } else if (tabItemDefaultIcon) {
    iconElement = <Box width="70px"><Image content={tabItemDefaultIcon} /></Box>;
  }

  return (
    <Tab
      wrapped
      className="flex flex-col items-center justify-center w-36 md:flex-row md:w-auto md:justify-start"
      label={title}
      icon={iconElement}
      iconPosition={iconPosition || 'top'}
      sx={baseStyles}
      onClick={(event) => {
        onChange(event, index);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};

export default TabLabel;
