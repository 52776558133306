import React from 'react';
import type {
  ContentfulComponentDefinition,
} from '#components/ContentfulComponent';
import type { TextDefinition } from '#contentful/Text';
import type { GatsbyImageProps } from '#contentful/Image';
import { useContentfulImage } from 'gatsby-source-contentful/hooks';
import Text from '#contentful/Text';
import { GatsbyImage } from 'gatsby-plugin-image';

type AlignmentOption = 'Top' | 'Center' | 'Bottom';

export type StepItemDefinition = ContentfulComponentDefinition & {
  title: string,
  titleHtmlTag: keyof JSX.IntrinsicElements,
  textColor: {
    value: string
  },
  titleBackground: {
    value: string
  },
  icon: GatsbyImageProps,
  horizontalIconAlignment?: AlignmentOption,
  content: TextDefinition,
  contentBackground: {
    value: string
  },
  internal: {
    type: 'ContentfulSubComponentStepItem'
  }
};

export type StepItemProps = {
  content: StepItemDefinition,
};

const getHorizontalAlignment = (value: AlignmentOption) => {
  switch (value) {
    case 'Top':
      return 'md:items-start';
    case 'Center':
      return 'md:items-center';
    case 'Bottom':
      return 'md:items-end';
    default:
      return 'md:items-center';
  }
};

const StepItem = ({
  content,
}: StepItemProps) => {
  const {
    title,
    textColor,
    titleBackground,
    icon,
    content: contentText,
    contentBackground,
    horizontalIconAlignment,
    titleHtmlTag: Tag,
  } = content;

  const dynamicImage = useContentfulImage({
    image: {
      url: icon?.url.replace('https:', ''),
      width: 600,
      height: 375,
      resizingBehavior: 'crop',
      quality: 100,
    },
  });

  return (
    <div className="bg-white shadow-lg rounded-md w-auto md:max-w-[600px] mr-2 ml-2">
      <div id="head" className="p-2 rounded-t-lg" style={{ backgroundColor: titleBackground.value, fontFamily: 'Poppins-Bold' }}>
        <Tag className="text-2xl pl-5 " style={{ color: textColor.value }}>{title}</Tag>
      </div>

      <div className=" p-6 flex flex-col md:flex-row rounded-b-lg" style={{ backgroundColor: contentBackground.value }}>
        {icon !== null && (
        <div className={`w-full md:w-1/5 md:pr-4 md:flex ${getHorizontalAlignment(horizontalIconAlignment ?? 'Center')} flex justify-center`}>
          <GatsbyImage
            loading="lazy"
            image={dynamicImage}
            alt={icon?.alt}
            className="w-20 h-20"
          />
        </div>
        )}

        <div className={`${icon !== null ? 'w-full md:w-4/5' : 'w-full'} mt-4 md:mt-0`}>
          <Text content={contentText} />
        </div>
      </div>
    </div>
  );
};

export default StepItem;
