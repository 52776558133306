import type { MouseEventHandler } from 'react';
import React from 'react';

import {
  createTheme,
  ListItemButton,
  ListItemText,
  ThemeProvider,
} from '@mui/material';

import baseTheme from '#components/Layout/theme';
import SmartLink from '#components/SmartLink';

export type NavMenuLinkDefinition = NavMenuLinkProps & {
  id: string,
  contentfulId?: string,
};

export type NavMenuLinkProps = {
  label: string,
  href?: string,
  slug?: string,
  openInNewWindow?: boolean,
  onClick?: MouseEventHandler<HTMLAnchorElement>,
  listStyles?: object,
  color?: string,
  menuOpen?: string,
  isMobile?: boolean
};

const themeNavMenuLink = createTheme(baseTheme, {
  typography: {
    body1: baseTheme.typography.body2,
  },
});

const NavMenuLink = ({
  label,
  href,
  slug,
  openInNewWindow = false,
  onClick,
  listStyles,
  color,
  menuOpen,
  isMobile,
}: NavMenuLinkProps) => (
  <ThemeProvider theme={themeNavMenuLink}>
    <SmartLink
      href={href}
      slug={slug}
      openInNewWindow={openInNewWindow}
      onClick={onClick}
      sx={{ textDecoration: 'none' }}
    >
      <ListItemButton sx={{ height: '100%', ...listStyles, ...((menuOpen && !isMobile) && { backgroundColor: themeNavMenuLink.palette.action.hover }) }}>
        <ListItemText sx={{ color: color || '#646466' }}>
          {label}
        </ListItemText>
      </ListItemButton>
    </SmartLink>
  </ThemeProvider>
);

export default NavMenuLink;
